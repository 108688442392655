var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"rounded":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5"}}),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('v-btn',{staticClass:"scrollTo mx-2",attrs:{"tile":"","href":"#youth"}},[_vm._v("Youth and Children")]),_c('v-btn',{staticClass:"scrollTo mx-2",attrs:{"tile":"","href":"#prayer"}},[_vm._v("Prayer")]),_c('v-btn',{staticClass:"scrollTo mx-2",attrs:{"tile":"","href":"#women"}},[_vm._v("Women")]),_c('v-btn',{staticClass:"scrollTo mx-2",attrs:{"tile":"","href":"#health"}},[_vm._v("Health")]),_c('v-btn',{staticClass:"scrollTo mx-2",attrs:{"tile":"","href":"#breathe"}},[_vm._v("Breathe Free Program")])],1)],1),_c('v-container',{attrs:{"id":"youth"}},[_c('h3',{staticClass:"h3 my-2"},[_vm._v("Youth and Children Ministries")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("These Ministries show love to our youth and children by spending time with them and helping them hear and understand the gospel of Christ. The ministry is built on the dynamics of Christian life-grace, worship, community and service. With outreach activities, social gatherings, sports, laughter and fun, young people come together to grow closer to Jesus Christ and stronger in their walk with Him.")]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-fab-transition',[_c('v-btn',{staticClass:"transition-swing",style:({
            marginBottom: `${_vm.$vuetify.application.snackbar}px`,
          }),attrs:{"aria-label":"Scroll To Top","rounded":"","title":"Scroll To Top","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-up")])],1)],1)],1)],1),_c('v-container',{attrs:{"id":"prayer"}},[_c('h3',{staticClass:"h3 my-2"},[_vm._v("Prayer Ministry")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("The following people are available for prayer requests should you need to contact at them any time:")]),_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_c('h4',{staticClass:"h5"},[_vm._v("Llew Daniel")])]),_c('td',[_c('h4',{staticClass:"h5"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"tel:705-966-2124"}},[_vm._v("705-966-2124")])])])]),_c('tr',[_c('td',[_c('h4',{staticClass:"h5"},[_vm._v("Inez Richards")])]),_c('td',[_c('h4',{staticClass:"h5"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"tel:705-969-8101"}},[_vm._v("705-969-8101")])])])]),_c('tr',[_c('td',[_c('h4',{staticClass:"h5"},[_vm._v("Ray Mongrain")])]),_c('td',[_c('h4',{staticClass:"h5"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"tel:705-560-8326"}},[_vm._v("705-560-8326")])])])]),_c('tr',[_c('td',[_c('h4',{staticClass:"h5"},[_vm._v("Pastor Elvis Dumitru")])]),_c('td',[_c('h4',{staticClass:"h5"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"tel:437-299-2630"}},[_vm._v("437-299-2630")])])])])])]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("For urgent prayer request/prayer chain contact: "),_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"green"},attrs:{"href":"mailto:wilsonl@eastlink.ca"}},[_vm._v("wilsonl@eastlink.ca")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-fab-transition',[_c('v-btn',{staticClass:"transition-swing",style:({
            marginBottom: `${_vm.$vuetify.application.snackbar}px`,
          }),attrs:{"aria-label":"Scroll To Top","rounded":"","title":"Scroll To Top","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-up")])],1)],1)],1)],1),_c('v-container',{attrs:{"id":"women"}},[_c('h3',{staticClass:"h3 my-2"},[_vm._v("Women's Ministries")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("Women's Ministries is a place to address the spiritual, emotional, physical and social needs of women. It encourages women to improve their potential for participation in the mission of the church. It is a support system for hurting women-whether they are suffering from divorce, abuse or simply loneliness. Women's Ministries recognizes that women have many Gifts of the Spirit and it attempts to help women discover and use these gifts to the glory of God. Women's Ministries is involved in many activities throughout the year.")]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-fab-transition',[_c('v-btn',{staticClass:"transition-swing",style:({
            marginBottom: `${_vm.$vuetify.application.snackbar}px`,
          }),attrs:{"aria-label":"Scroll To Top","rounded":"","title":"Scroll To Top","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-up")])],1)],1)],1)],1),_c('v-container',{attrs:{"id":"health"}},[_c('h3',{staticClass:"h3 my-2"},[_vm._v("Health Ministries")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("Health Ministries encourages healthful living in our community as well as in our local church. This ministry also presents occasional vegetarian cooking classes.")]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-fab-transition',[_c('v-btn',{staticClass:"transition-swing",style:({
            marginBottom: `${_vm.$vuetify.application.snackbar}px`,
          }),attrs:{"aria-label":"Scroll To Top","rounded":"","title":"Scroll To Top","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-up")])],1)],1)],1)],1),_c('v-container',{attrs:{"id":"breathe"}},[_c('h3',{staticClass:"h3 my-2"},[_vm._v("Breathe Free Program")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("Pledging to kick the nicotine habit is among the most common New Year’s resolutions in America, but, in many cases, this resolution is broken soon after it is made. If you're concerned about your smoking habit, and the health of your loved ones, you must attend.")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("The “Breathe Free” program, with it's 50 year track record of successfully helping people break free from tobacco, can help you succeed where others have failed. We will discuss the effects of cigarettes on the body, help you set your goals, connect with others who have been where you are, and provide tips to butt out once and for all.")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("This program is free so sign up now. Call the numbers below and leave your co-ordinates, and someone will contact you.")]),_c('h5',{staticClass:"h5 my-2"},[_vm._v("Please contact us at "),_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"green"},attrs:{"href":"tel:705-566-6150"}},[_vm._v("(705) 566-6150")]),_vm._v(", or "),_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"green"},attrs:{"href":"tel:705-560-8326"}},[_vm._v("(705) 560-8326")])]),_c('v-row',{staticClass:"my-4",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"contain":"","max-width":"800","src":require("@/assets/images/breathe_free_poster.jpg")}})],1),_c('v-row',{staticClass:"my-4",attrs:{"justify":"center"}},[_c('v-fab-transition',[_c('v-btn',{staticClass:"transition-swing",style:({
            marginBottom: `${_vm.$vuetify.application.snackbar}px`,
          }),attrs:{"aria-label":"Scroll To Top","rounded":"","title":"Scroll To Top","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-up")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }