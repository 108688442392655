<template>
  <v-card rounded="0">
    <v-row>
      <v-col cols="12" lg="5">
      </v-col>
      <v-col cols="12" lg="7">
        <v-btn tile href="#youth" class="scrollTo mx-2">Youth and Children</v-btn>
        <v-btn tile href="#prayer" class="scrollTo mx-2">Prayer</v-btn>
        <v-btn tile href="#women" class="scrollTo mx-2">Women</v-btn>
        <v-btn tile href="#health" class="scrollTo mx-2">Health</v-btn>
        <v-btn tile href="#breathe" class="scrollTo mx-2">Breathe Free Program</v-btn>
      </v-col> 
    </v-row>
    <v-container id="youth">
      <h3 class="h3 my-2">Youth and Children Ministries</h3>
      <h5 class="h5 my-2">These Ministries show love to our youth and children by spending time with them and helping them hear and understand the gospel of Christ. The ministry is built on the dynamics of Christian life-grace, worship, community and service. With outreach activities, social gatherings, sports, laughter and fun, young people come together to grow closer to Jesus Christ and stronger in their walk with Him.</h5>
      <v-row justify="center" class="mt-4">
        <v-fab-transition>
          <v-btn
            aria-label="Scroll To Top"
            :style="{
              marginBottom: `${$vuetify.application.snackbar}px`,
            }"
            rounded
            title="Scroll To Top"
            class="transition-swing"
            color="primary"
            @click="toTop"
          >
            <v-icon color="white">mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
    <v-container id="prayer">
      <h3 class="h3 my-2">Prayer Ministry</h3>
      <h5 class="h5 my-2">The following people are available for prayer requests should you need to contact at them any time:</h5>
      <v-simple-table>
        <tbody>
          <tr>
            <td><h4 class="h5">Llew Daniel</h4></td>
            <td><h4 class="h5"><a class="text-decoration-none" href="tel:705-966-2124">705-966-2124</a></h4></td>
          </tr>
          <tr>
            <td><h4 class="h5">Inez Richards</h4></td>
            <td><h4 class="h5"><a class="text-decoration-none" href="tel:705-969-8101">705-969-8101</a></h4></td>
          </tr>
          <tr>
            <td><h4 class="h5">Ray Mongrain</h4></td>
            <td><h4 class="h5"><a class="text-decoration-none" href="tel:705-560-8326">705-560-8326</a></h4></td>
          </tr>
          <tr>
            <td><h4 class="h5">Pastor Elvis Dumitru</h4></td>
            <td><h4 class="h5"><a class="text-decoration-none" href="tel:437-299-2630">437-299-2630</a></h4></td>
          </tr>
        </tbody>
      </v-simple-table>
      <h5 class="h5 my-2">For urgent prayer request/prayer chain contact: <a style="color:green" class="text-decoration-none" href="mailto:wilsonl@eastlink.ca">wilsonl@eastlink.ca</a></h5>
      <v-row justify="center" class="mt-4">
        <v-fab-transition>
          <v-btn
            aria-label="Scroll To Top"
            :style="{
              marginBottom: `${$vuetify.application.snackbar}px`,
            }"
            rounded
            title="Scroll To Top"
            class="transition-swing"
            color="primary"
            @click="toTop"
          >
            <v-icon color="white">mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
    <v-container id="women">
      <h3 class="h3 my-2">Women's Ministries</h3>
      <h5 class="h5 my-2">Women's Ministries is a place to address the spiritual, emotional, physical and social needs of women. It encourages women to improve their potential for participation in the mission of the church. It is a support system for hurting women-whether they are suffering from divorce, abuse or simply loneliness. Women's Ministries recognizes that women have many Gifts of the Spirit and it attempts to help women discover and use these gifts to the glory of God. Women's Ministries is involved in many activities throughout the year.</h5>
      <v-row justify="center" class="mt-4">
        <v-fab-transition>
          <v-btn
            aria-label="Scroll To Top"
            :style="{
              marginBottom: `${$vuetify.application.snackbar}px`,
            }"
            rounded
            title="Scroll To Top"
            class="transition-swing"
            color="primary"
            @click="toTop"
          >
            <v-icon color="white">mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
    <v-container id="health">
      <h3 class="h3 my-2">Health Ministries</h3>
      <h5 class="h5 my-2">Health Ministries encourages healthful living in our community as well as in our local church. This ministry also presents occasional vegetarian cooking classes.</h5>
      <v-row justify="center" class="mt-4">
        <v-fab-transition>
          <v-btn
            aria-label="Scroll To Top"
            :style="{
              marginBottom: `${$vuetify.application.snackbar}px`,
            }"
            rounded
            title="Scroll To Top"
            class="transition-swing"
            color="primary"
            @click="toTop"
          >
            <v-icon color="white">mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
    <v-container id="breathe">
      <h3 class="h3 my-2">Breathe Free Program</h3>
      <h5 class="h5 my-2">Pledging to kick the nicotine habit is among the most common New Year’s resolutions in America, but, in many cases, this resolution is broken soon after it is made. If you're concerned about your smoking habit, and the health of your loved ones, you must attend.</h5>
      <h5 class="h5 my-2">The “Breathe Free” program, with it's 50 year track record of successfully helping people break free from tobacco, can help you succeed where others have failed. We will discuss the effects of cigarettes on the body, help you set your goals, connect with others who have been where you are, and provide tips to butt out once and for all.</h5>
      <h5 class="h5 my-2">This program is free so sign up now. Call the numbers below and leave your co-ordinates, and someone will contact you.</h5>
      <h5 class="h5 my-2">Please contact us at <a class="text-decoration-none" style="color:green" href="tel:705-566-6150">(705) 566-6150</a>, or <a style="color:green" class="text-decoration-none" href="tel:705-560-8326">(705) 560-8326</a></h5>
      <v-row justify="center" class="my-4">
        <v-img contain max-width="800" src="@/assets/images/breathe_free_poster.jpg"></v-img>
      </v-row>
      <v-row justify="center" class="my-4">
        <v-fab-transition>
          <v-btn
            aria-label="Scroll To Top"
            :style="{
              marginBottom: `${$vuetify.application.snackbar}px`,
            }"
            rounded
            title="Scroll To Top"
            class="transition-swing"
            color="primary"
            @click="toTop"
          >
            <v-icon color="white">mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>

export default {
  methods: {
    onScroll () {
      const top = (
        window.pageYOffset ||
          document.documentElement.offsetTop ||
          0
      )

      this.fab = top > 300
    },
    toTop () {
      if (this.$route.hash) {
        this.$router.push({ hash: '' })
      }

      this.$vuetify.goTo(0)
    }
  }
}
</script>