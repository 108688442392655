<template>
  <div>
    <service-bar></service-bar>
    <TopParallax
      title="MINISTRIES"
      :img-src="imgSrc"
    />
    <ministry-info></ministry-info>
  </div>
</template>

<script>
import ServiceBar from '@/components/ServiceBar.vue'
import TopParallax from '@/components/TopParallax.vue'
import MinistryInfo from '@/components/MinistryInfo.vue'

export default {
  components: {
    TopParallax,
    ServiceBar,
    MinistryInfo
  },
  data() {
    return {
      imgSrc: require('@/assets/images/ministries_banner.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Ministries'
      }
    }
  }
}
</script>
